import { createApp } from 'vue'
import store from './store'
import router from './router'
import App from './App.vue'
import Antd from 'ant-design-vue';
import VuePrintNb from "vue3-print-nb"
import 'ant-design-vue/dist/reset.css';


const app = createApp(App)

// 全局注册
app.use(store)
app.use(router)
app.use(Antd)
// 加载打印组件
app.use(VuePrintNb)

app.mount('#app')
