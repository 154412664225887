import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: "/",
        name: "HomeView",
        component: () => import("../views/home/HomeView.vue")
    },
    {
        path: "/sticker/print/:data",
        name: "StickerPrintView",
        component: () => import("../views/sticker/StickerPrintView.vue")
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFoundView",
        component: () => import("../views/404/NotFoundView.vue"),
    },
]


const router = createRouter({
    base: "/",
    mode: "history", // 启用历史模式
    history: createWebHistory(),
    routes,
})


export default router
