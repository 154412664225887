import { Store } from "vuex"

const store = new Store({
    state: {
        count: 0
    },
    mutations: {
        increment(state) {
            state.count++
        }
    }
})

export default store
